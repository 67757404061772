import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
// Import other components
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Products from './pages/Products';
import ScrollToTop from './components/ScrollToTop';
import Dosing from './pages/Dosing';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';


function App() {
  return (
    <>
      <Router>
        <div className="appContainer">
          <ScrollToTop />
          <Navbar />
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/dosing" element={<Dosing />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
