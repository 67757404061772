import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, List, ListItem, ListItemText } from '@mui/material';
import WordsLogo from '../assets/PsychPharmsWords.png';

const MyNavbar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <List>
                <ListItem button component={RouterLink} to="/">
                    <ListItemText primary="Home" primaryTypographyProps={{ style: { fontSize: '20px', textAlign: 'right' } }} />
                </ListItem>
                <ListItem button component={RouterLink} to="/products">
                    <ListItemText primary="Products" primaryTypographyProps={{ style: { fontSize: '20px', textAlign: 'right' } }} />
                </ListItem>
                {/* <ListItem button component={RouterLink} to="/dosing">
                    <ListItemText primary="Dosing" primaryTypographyProps={{ style: { fontSize: '20px', textAlign: 'right' } }} />
                </ListItem> */}
                <ListItem button component={RouterLink} to="/faq">
                    <ListItemText primary="FAQ" primaryTypographyProps={{ style: { fontSize: '20px', textAlign: 'right' } }} />
                </ListItem>
                <ListItem button component={RouterLink} to="/contact">
                    <ListItemText primary="Contact" primaryTypographyProps={{ style: { fontSize: '20px', textAlign: 'right' } }} />
                </ListItem>
                {/* Repeat for other links */}
            </List>
        </Box>
    );

    return (
        <AppBar position="static" color='transparent' elevation={0} sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}>
            <Toolbar sx={{ flexWrap: 'wrap', color: 'white' }}>
                <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }} style={{ marginTop: '15px', marginLeft: '15px' }}>
                    <RouterLink to="/">
                        <img src={WordsLogo} alt="Logo" style={{ maxWidth: '200px' }} />
                    </RouterLink>
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2, display: { sm: 'none' } }}
                    onClick={handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button color="inherit" component={RouterLink} to="/products"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'silver', // Apply gold background on hover
                                color: '#202a2e',
                            },
                        }}>Products</Button>
                    {/* Repeat for other links */}
                </Box>

                {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button color="inherit" component={RouterLink} to="/dosing"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'silver', // Apply gold background on hover
                                color: '#202a2e',
                            },
                        }}>Dosing</Button>
                </Box> */}


                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button color="inherit" component={RouterLink} to="/faq"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'silver', // Apply gold background on hover
                                color: '#202a2e',
                            },
                        }}>FAQ</Button>
                    {/* Repeat for other links */}
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Button color="inherit" component={RouterLink} to="/contact"
                        sx={{
                            '&:hover': {
                                backgroundColor: 'silver', // Apply gold background on hover
                                color: '#202a2e',
                            },
                        }}>Contact</Button>
                    {/* Repeat for other links */}
                </Box>
            </Toolbar>
            <Drawer
                anchor="right" // Set the drawer to open from the right
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }} // Better open performance on mobile.
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: '#0A1116', color: 'white', width: '54vw', borderTopLeftRadius: '8px'
                    }, // Set the background color to black and text color to white
                }}            >
                {drawer}
            </Drawer>
        </AppBar>
    );
};

export default MyNavbar;
