import React from 'react';
import { Grid } from '@mui/material';
import PhatBar from '../assets/PhatBar.jpeg';
import Gummies from '../assets/Gummies.jpeg';
import Raw from '../assets/Raw.jpeg';
import Nectar from '../assets/Nectar.jpeg';
import BlueBack from '../assets/BlueBack.png';

const Products = () => {
    return (
        <div style={styles.container}>
            <Grid container spacing={2} justifyContent="center">
                {/* Repeat for each item */}
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={PhatBar} alt="Related Content" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Phat Bars</h2>
                            <p>Luxurious European milk chocolate with a psychedelic twist. Handcrafted for a unique, rich experience. Each bar combines creamy texture with a special formula. Ideal for those seeking a delicious, mind-expanding treat.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Gummies} alt="Related Content" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Psych Gummies</h2>
                            <p>PsychPharms gummies, crafted for cognitive enhancement and mental clarity, contain a precise dose of premium extract for effectiveness. Delightful flavors and chewy texture make them a treat for those seeking to boost mental capabilities.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Raw} alt="Related Content" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Pharm Fresh</h2>
                            <p>Harness the power of nature for cognitive enhancement. These carefully cultivated mushrooms offer a unique experience in mental clarity and creative thinking, also ideal for those seeking an intense psychedelic journey.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={Nectar} alt="Related Content" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Psych Nectar</h2>
                            <p>A sweet, natural enhancer designed for deeper mental journeys and exploration. This pure, potent honey is crafted for mind-expanding experiences, offering a harmonious blend of natural sweetness and psychedelic properties. </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={BlueBack} alt="Related Content" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Pharm Caps</h2>
                            <p>Expertly blended with nature's finest mushrooms psychedelic for a powerful mental boost. These capsules enhance clarity, creativity, and introspection, offering a gentle yet profound journey into the depths of your mind.</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <div style={styles.itemWrapper}>
                        <img src={BlueBack} alt="Related Content" style={styles.contentImage} />
                        <div style={styles.textContent}>
                            <h2 style={styles.sectionHeading}>Psych Bytes</h2>
                            <p>Boutique chocolates with a powerful punch. Each small, rich piece is packed with potent psychoactive mushrooms, guaranteeing a strong, mind-altering experience. Perfect for those seeking a delicious way to achieve an intense cognitive high.</p>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        textAlign: 'left',
        maxWidth: '1200px',
        margin: '0 auto',
    },
    itemWrapper: {
        maxWidth: '320px',
        width: '100%',
        margin: '0 auto',
    },
    contentImage: {
        width: '100%',
        height: '300px',
        objectFit: 'cover',
        borderTopRightRadius: '8px',
        borderTopLeftRadius: '8px',
    },
    textContent: {
        width: '100%',
        boxSizing: 'border-box', // Include padding and border in the box's width
        border: '3px solid rgba(255, 215, 0, 0.6)',
        borderTop: '0px',
        marginTop: '-4px',
        padding: '20px',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'Center',
        fontSize: '26px',
    },

};

export default Products;
