const Modal = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modalContent}>
                {children}
                <button style={styles.closeButton} onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const styles = {
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
    },
    modalContent: {
        backgroundColor: '#282c34',
        padding: '20px',
        borderRadius: '8px',
        color: '#FFF',
        maxWidth: '500px',
        width: '80%',
    },
    closeButton: {
        backgroundColor: 'silver',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 15px',
        cursor: 'pointer',
        fontSize: '14px',
        marginTop: '10px',
        color: 'black',
    },

};

export default Modal;
