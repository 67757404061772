import React, { useState } from 'react';

const FAQ = () => {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

    const faqData = [
        { question: 'What is Psilocybin?', answer: 'Psilocybin is a naturally occurring psychedelic compound found in certain species of mushrooms.' },
        { question: 'How is Psilocybin used?', answer: 'Psilocybin is used in various therapeutic settings and is also known for its recreational use.' },
        { question: 'Can Psilocybin be used for medical purposes?', answer: 'Research is ongoing into the potential medical uses of psilocybin, particularly in the treatment of mental health disorders such as depression, anxiety, and PTSD.' },
        { question: 'What are the effects of Psilocybin?', answer: 'Effects of psilocybin can include altered perception of time and space, euphoria, visual and auditory hallucinations, changes in thought and emotion, and a sense of spiritual experience.' },
        { question: 'How long do the effects of Psilocybin last?', answer: 'The effects of psilocybin typically begin within 20 to 40 minutes of ingestion and can last anywhere from 4 to 6 hours.' },
        // Add additional FAQs here as needed
    ];


    const toggleAnswer = (index) => {
        setActiveQuestionIndex(activeQuestionIndex === index ? null : index);
    };

    return (
        <div style={styles.container}>
            {faqData.map((faq, index) => (
                <div key={index} style={styles.faqItem}>
                    <button style={styles.question} onClick={() => toggleAnswer(index)}>
                        <span style={styles.icon}>
                            {activeQuestionIndex === index ? '-' : '+'}
                        </span>
                        {faq.question}
                    </button>
                    {activeQuestionIndex === index && (
                        <p style={styles.answer}>{faq.answer}</p>
                    )}
                </div>
            ))}
        </div>
    );
};


const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        margin: '0 auto',
        textAlign: 'left',
    },
    header: {
        textAlign: 'center',
        marginBottom: '30px',
    },
    faqItem: {
        marginBottom: '10px',
        borderBottom: '1px solid #ccc',
        paddingBottom: '10px',
        color: 'white',
    },
    question: {
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'left',
        cursor: 'pointer',
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '10px 0',
        color: 'white',
        display: 'flex', // Added for aligning icon and text
        alignItems: 'center', // Align items vertically
    },
    icon: {
        marginRight: '10px', // Space between icon and text
        fontSize: '20px', // Slightly larger icon size
        fontWeight: 'bold',
    },
    answer: {
        fontSize: '16px',
        padding: '5px 0 10px 0',
        color: 'white'
    },
};

export default FAQ;
