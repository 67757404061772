import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import Logo from '../assets/TopWordShroom.png';
import Bakini from '../assets/Room.jpeg';
import Eating from '../assets/MJ.jpg';
import UnderWs from '../assets/UnderWs.png';
import Words from '../assets/PsychPharmsWords.png';
import BGMush from '../assets/Hydra.jpeg';



const Home = () => {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <img src={Logo} alt="Logo" style={styles.logo} />
                <img src={UnderWs} alt="Logo" style={styles.logows} />

                <p style={styles.missionStatement}>
                    At Psych Pharms, we are dedicated to uleashing the transformative potential of psychedelic mushrooms, pioneering a new era of natural wellness and mental health solutions. Our mission is to responsibly harness the power of psilocybin and related compounds to offer groundbreaking experiences that promote psychological, emotional, and spiritual well-being.
                </p>
            </header>

            <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '40px' }}>
                <Grid item xs={12} md={6} style={styles.gridItem}>
                    <img src={BGMush} alt="Related Content" style={styles.contentImage} />
                </Grid>
                <Grid item xs={12} md={6} style={styles.gridItem}>
                    <div style={styles.textContent}>
                        <h2 style={styles.sectionHeading}>About Us</h2>
                        <p>Founded in 2020, Psych Pharms emerged from a shared vision to explore and harness the profound possibilities of psychedelic mushrooms. We are a collective of passionate experts, researchers, and advocates, united in our commitment to unlocking the therapeutic potential of psilocybin and other natural compounds for mental wellness and personal growth.</p>
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                component={RouterLink}
                                to="/products"
                                style={styles.goldButton}
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'silver', // Apply gold background on hover
                                        color: '#202a2e',
                                    },
                                }}
                            >
                                See Products
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6} style={styles.gridItem} order={{ md: '1', lg: '2' }}>
                    <img src={Eating} alt="Related Content" style={styles.contentImage} />
                </Grid>
                <Grid item xs={12} md={6} style={styles.gridItem} order={{ md: '2', lg: '1' }}>
                    <div style={styles.textContent}>
                        <h2 style={styles.sectionHeading}>Our Process</h2>
                        <p>We're commited to rigorous scientific research and ethical cultivation practices, ensuring the highest quality and safety standards in every product we offer. Our approach is rooted in a deep respect for the ancient wisdom surrounding these natural compounds, coupled with forward-thinking modern scientific methods.</p>
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                component={RouterLink}
                                to="/faq"
                                style={styles.goldButton}
                                sx={{
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: 'silver', // Apply gold background on hover
                                        color: '#202a2e',
                                    },
                                }}
                            >
                                View FAQ
                            </Button>
                        </div>
                    </div>
                </Grid>

            </Grid>

            <Grid container spacing={0} columnSpacing={12} justifyContent="center" style={{ marginTop: '20px', marginBottom: '15px' }}>
                <Grid item xs={12} md={3} style={styles.gridItem} order={{ md: '1', lg: '2' }}>
                    <div style={styles.textContentC}>
                        <p style={{ textAlign: 'center', }}>Our commitment to excellence aims to revolutionize effective alternatives for various psychological conditions.</p>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} style={styles.gridItem} order={{ md: '1', lg: '2' }}>
                    <div style={styles.textContentC}>
                        <p style={{ textAlign: 'center', }}>We exist to unleash new pathways for healing, personal growth, and enhanced wellbeing.</p>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} style={styles.gridItem} order={{ md: '1', lg: '2' }}>
                    <div style={styles.textContentC}>
                        <p style={{ textAlign: 'center', }}>We strive to foster deeper connections through mindful and respectful use of psychedelic mushrooms.</p>
                    </div>
                </Grid>


            </Grid>




            <img src={Words} alt="Logo" style={{ height: 'auto', width: '80%' }} />

            {/* Add more sections as needed */}
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: 'white',
        margin: '0 auto',
        maxWidth: '85vw',
        textAlign: 'center',
    },
    header: {
        padding: '20px',
    },
    logo: {
        width: '90%',
        maxWidth: '400px',
        height: 'auto',
        margin: '0 auto',
    },
    logows: {
        width: '100%',
        height: 'auto',
        margin: '0 auto',
        marginTop: '15px',
    },
    missionStatement: {
        fontSize: '18px',
        maxWidth: '900px',
        margin: '20px auto',
        textAlign: 'center',
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'left',
    },
    contentImage: {
        width: '300px',
        height: '400px',
        objectFit: 'cover',
        objectPosition: 'center 30%',
        borderRadius: '8px',
        margin: 'auto',
    },
    textContent: {
        width: 'auto',
        maxWidth: '400px',
        margin: '20px auto',
        border: '3px solid rgba(255, 215, 0, 0.6)',
        padding: '20px',
        borderRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
    },
    textContentC: {
        width: '100%',
        maxWidth: '400px',
        margin: '20px auto',
        borderRadius: '8px',
        color: 'white',
        backgroundColor: 'transparent',
        textAlign: 'center',
        fontSize: '21px'
    },
    sectionHeading: {
        color: 'white',
        textAlign: 'left',
        fontSize: '26px',
    },
};

export default Home;
