import React, { useState } from 'react';
import Modal from '../components/Modal';

const Dosing = () => {
    const [activeDose, setActiveDose] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const doses = [
        { range: '0.1 - 0.5', level: 'Microdose', description: 'Subtle, may improve mood and focus.' },
        { range: '0.5 - 2.0', level: 'Mild', description: 'Noticeable changes in perception, mood enhancement.' },
        { range: '2.0 - 3.5', level: 'Moderate', description: 'Intense emotional experiences, heightened sensory perception.' },
        { range: '3.5 - 5.0', level: 'Strong', description: 'Profound introspection, altered perception of space and time.' },
        { range: '5.0+', level: 'Heavy', description: 'Extremely intense experience, complete loss of connection to conventional reality.' },
    ];
    const openModal = (index) => {
        setActiveDose(index);
        setShowModal(true);
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Dosing Chart</h1>
            <table style={styles.table}>
                <thead style={styles.tableHead}>
                    <tr>
                        <th style={styles.tableHeader}>Dose (grams)</th>
                        <th style={styles.tableHeader}>Effect Level</th>
                        <th style={styles.tableHeader}>User Experience</th>
                    </tr>
                </thead>
                <tbody>
                    {doses.map((dose, index) => (
                        <tr key={index} style={styles.tableRow}>
                            <td style={styles.tableCell}>{dose.range}</td>
                            <td style={styles.tableCell}>{dose.level}</td>
                            <td style={styles.tableCell}>
                                <button
                                    style={styles.button}
                                    onClick={() => openModal(index)}
                                >
                                    Show Details
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {showModal && activeDose !== null && (
                <Modal show={showModal} onClose={() => setShowModal(false)}>
                    <h2>{doses[activeDose].level}</h2>
                    <p>{doses[activeDose].description}</p>
                </Modal>
            )}
        </div>
    );
};


const styles = {
    container: {
        padding: '20px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        marginTop: '-50px',
    },
    header: {
        color: 'white',
        marginBottom: '10px',
    },
    instruction: {
        color: '#adb5bd',
        marginBottom: '20px',
    },
    table: {
        width: '95%',
        maxWidth: '800px',
        borderCollapse: 'collapse',
        margin: '20px 0',
        backgroundColor: '#1e2125',
        borderRadius: '8px',
        overflow: 'hidden',
    },
    tableHead: {
        backgroundColor: '#3f4448',
    },
    tableHeader: {
        padding: '15px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    tableRow: {
        borderBottom: '1px solid #343a40',
    },
    tableCell: {
        padding: '15px',
        fontSize: '14px',
    },
    button: {
        padding: '10px 15px',
        margin: '5px',
        backgroundColor: 'silver',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },

};

export default Dosing;
