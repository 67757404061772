import React from 'react';
import Logo from '../assets/Logo.png'

const Contact = () => {
    return (
        <div style={styles.container}>
            <img src={Logo} alt="Psych Pharms Logo" style={styles.logo} />
            <h1 style={styles.header}>Contact Us</h1>
            <p style={styles.text}>If you have any questions or need assistance, please reach out to us.</p>
            <a href="mailto:PsychPharms@gmail.com" style={styles.email}>PsychPharms@yahoo.com</a>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFF',
        padding: '20px',
        marginTop: '-120px',
        marginBottom: '-50px'
    },
    logo: {
        maxWidth: '300px',
        height: 'auto',
        marginTop: '20px',
    },
    header: {
        fontSize: '28px',
        marginBottom: '10px',
    },
    text: {
        fontSize: '18px',
        marginBottom: '10px',
    },
    email: {
        fontSize: '20px',
        color: 'gold',
        textDecoration: 'none',
    },
};

export default Contact;
